import auth from '@/middleware/auth.js'

export default [{
    path: '',
    component: () =>
        import ('@/layouts/Constelacion'),
    children: [{
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/apps/account/Dashboard'),
        meta: {
            title: 'Account',
            middleware: auth
        }
    }, {
        path: '/account/organizaciones',
        name: 'account_organizaciones',
        component: () =>
            import ('@/apps/account/pages/Organizacion'),
        meta: {
            title: 'Organizaciones - Account',
            middleware: auth
        }
    }, {
        path: '/account/aplicaciones',
        name: 'account_aplicaciones',
        component: () =>
            import ('@/apps/account/pages/Aplicacion'),
        meta: {
            title: 'Aplicaciones - Account',
            middleware: auth
        }
    }, {
        path: '/account/roles',
        name: 'account_roles',
        component: () =>
            import ('@/apps/account/pages/Rol'),
        meta: {
            title: 'Roles - Account',
            middleware: auth
        }
    }, {
        path: '/account/usuarios',
        name: 'account_usuarios',
        component: () =>
            import ('@/apps/account/pages/Usuario'),
        meta: {
            title: 'Usuarios - Account',
            middleware: auth
        }
    }, {
        path: '/account/tokens',
        name: 'account_tokens',
        component: () =>
            import ('@/apps/account/pages/Token'),
        meta: {
            title: 'Tokens - Account',
            middleware: auth
        }
    }, {
        path: '/account/sesiones',
        name: 'account_sesiones',
        component: () =>
            import ('@/apps/account/pages/Sesion'),
        meta: {
            title: 'Sesiones - Account',
            middleware: auth
        }
    }]
}]